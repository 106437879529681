<template>
  <div class="not-found">
    <h2>Whoops</h2>
    <h4>
      It looks like this page doesn't exist.<br />
      Go back <router-link to="/">home</router-link>
    </h4>
  </div>
</template>

<style scoped>
.not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 120px auto;
  text-align: center;
  width: 520px;
}
h4 {
  line-height: 28px;
  margin-top: 12px;
}
</style>
